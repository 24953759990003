import { produce, Draft } from 'immer'

import {
  IAction,
  TActionTypes
} from '@Root/types'

import {
  IDemoState,
  IDemoActionTypes,
  DemoActionTypes,
  ISaveUrlInStatePayload,
} from './types'


export const initialState: IDemoState = {
  videoUrl: '',
  needsDownload: false,
  submitted: false,
}

type TAction = TActionTypes | IDemoActionTypes

export const demoReducer = (
  state: IDemoState = initialState,
  action: TAction,
): IDemoState => {
  return produce(state, draft => {
    switch (action.type) {
      case DemoActionTypes.SAVE_URL_IN_STATE:
        saveUrlInState(draft, action)
        break

      case DemoActionTypes.SET_SUBMITTED:
        setSubmitted(draft)
        break

      default:
        break
    }
  })
}

function isSaveUrlInStateAction(arg: any): arg is IAction<ISaveUrlInStatePayload> {
  return arg.type === DemoActionTypes.SAVE_URL_IN_STATE
}

const saveUrlInState = (draft: Draft<IDemoState>, action: TAction) => {
  if (!isSaveUrlInStateAction(action)) { return }

  draft.videoUrl = action.payload.videoUrl
  draft.needsDownload = action.payload.needsDownload
}

const setSubmitted = (draft: Draft<IDemoState>) => {
  draft.submitted = true
}
