import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects' // tslint:disable-line:no-duplicate-imports
import showConfetti from '@Utils/ShowConfettiAnimation'

import Logger from '@Utils/Logger'

import {
  fetchProjectVersionSettingsTtsSuccess,
  fetchProjectVersionSettingsTtsFailure,
  setupTtsFailure,
} from './actions'

import {
  ProjectVersionSettingsTtsActionTypes,
  IFetchProjectVersionSettingsTtsSuccessResponsePayload,
  ISetupTtsSuccessResponsePayload,
} from './types'

import * as API from './api'

interface IAction extends Action {
  readonly payload: object
}

const call: any = Eff.call

const SHORT_TIMESPAN = 2000 // ms

function* fetchProjectVersionSettingsTtsSaga(_action: IAction): Generator<any, any, any> {
  try {
    const response = yield call(API.fetchProjectVersionSettingsTts)

    const data = response.data as IFetchProjectVersionSettingsTtsSuccessResponsePayload

    yield put(fetchProjectVersionSettingsTtsSuccess(data))
  } catch (e) {
    Logger.error(e, 'Language data could not be loaded')
    yield put(fetchProjectVersionSettingsTtsFailure())
  }
}

function* setupTtsSaga(action: IAction): Generator<any, any, any> {
  try {
    const response = yield call(API.setupTts, action.payload)

    const data = response.data as ISetupTtsSuccessResponsePayload

    showConfetti()

    setTimeout(() => {
      window.location.href = data.editorUrl
    }, SHORT_TIMESPAN)

  } catch (e) {
    Logger.error(e, 'Project cannot be created')
    yield put(setupTtsFailure())
  }
}

function* projectVersionSettingsTtsSagas() {
  yield takeLatest(ProjectVersionSettingsTtsActionTypes.FETCH_PROJECT_VERSION_SETTINGS_TTS, fetchProjectVersionSettingsTtsSaga)
  yield takeLatest(ProjectVersionSettingsTtsActionTypes.SETUP_TTS, setupTtsSaga)
}

export default projectVersionSettingsTtsSagas
