import { produce, Draft } from 'immer'
import { IActionSuccess, TActionTypes } from '@Root/types'
import map from 'lodash/map'

import {
  IGuidelineState,
  GuidelineActionTypes,
  TGuidelineActionTypes,
  IFetchGuidelinesSuccessResponseBody,
} from './types'

export const initialState: IGuidelineState = {
  guidelines: [],
  loading: false,
  modifying: false
}

type TAction = TActionTypes | TGuidelineActionTypes

export const guidelinesReducer = (
  state: IGuidelineState = initialState,
  action: TAction
): IGuidelineState => {
  return produce(state, draft => {
    switch (action.type) {
      case GuidelineActionTypes.FETCH_GUIDELINES:
        fetchGuidelines(draft)
        break

      case GuidelineActionTypes.FETCH_GUIDELINES_SUCCESS:
        fetchGuidelinesSuccess(draft, action)
        break

      case GuidelineActionTypes.FETCH_GUIDELINES_FAILURE:
        fetchGuidelinesFailure(draft)
        break

      case GuidelineActionTypes.CREATE_GUIDELINE:
      case GuidelineActionTypes.UPDATE_GUIDELINE:
      case GuidelineActionTypes.DELETE_GUIDELINE:
      case GuidelineActionTypes.UPDATE_DEFAULT_GUIDELINE:
      case GuidelineActionTypes.DELETE_DEFAULT_GUIDELINE:
        startModifying(draft)
        break

      case GuidelineActionTypes.CREATE_GUIDELINE_SUCCESS:
      case GuidelineActionTypes.CREATE_GUIDELINE_FAILURE:
      case GuidelineActionTypes.UPDATE_GUIDELINE_SUCCESS:
      case GuidelineActionTypes.UPDATE_GUIDELINE_FAILURE:
      case GuidelineActionTypes.DELETE_GUIDELINE_SUCCESS:
      case GuidelineActionTypes.DELETE_GUIDELINE_FAILURE:
      case GuidelineActionTypes.UPDATE_DEFAULT_GUIDELINE_SUCCESS:
      case GuidelineActionTypes.UPDATE_DEFAULT_GUIDELINE_FAILURE:
      case GuidelineActionTypes.DELETE_DEFAULT_GUIDELINE_SUCCESS:
      case GuidelineActionTypes.DELETE_DEFAULT_GUIDELINE_FAILURE:
        stopModifying(draft)
        break

      default:
        break
    }
  })
}

const fetchGuidelines = (draft: Draft<IGuidelineState>) => {
  draft.loading = true
}

function isIFetchGuidelinesSuccessAction(arg: any): arg is IActionSuccess<IFetchGuidelinesSuccessResponseBody> {
  return arg.type === GuidelineActionTypes.FETCH_GUIDELINES_SUCCESS
}

const fetchGuidelinesSuccess = (draft: Draft<IGuidelineState>, action: TAction) => {
  if (!isIFetchGuidelinesSuccessAction(action)) { return }

  draft.guidelines = map(action.payload!.data, data => {
    return ({
      id: parseInt(data.id, 10),
      attributes: data.attributes
    })
  })
  draft.loading = false
}

const fetchGuidelinesFailure = (draft: Draft<IGuidelineState>) => {
  draft.loading = false
}

const startModifying = (draft: Draft<IGuidelineState>) => {
  draft.modifying = true
}

const stopModifying = (draft: Draft<IGuidelineState>) => {
  draft.modifying = false
}
