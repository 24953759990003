import { produce, Draft } from 'immer'
import { IAction, IActionSuccess, TActionTypes } from '@Root/types'
import map from 'lodash/map'

import {
  ITermbaseState,
  TermbaseActionTypes,
  TTermbaseActionTypes,
  IFetchTermbasesSuccessResponseBody,
  IFetchTermbaseEntriesSuccessResponseBody,
  ISetActiveTermbaseIdPayload,
  ISetActivePagePayload,
  ISetSearchTermPayload,
} from './types'

export const initialState: ITermbaseState = {
  termbases: [],
  termbaseEntries: [],
  activePage: 1,
  totalPages: 1,
  totalTermbaseEntryCount: 0,
  loadingTermbases: false,
  loadingTermbaseEntries: false,
  modifying: false,
  allFullnameLanguages: []
}

type TAction = TActionTypes | TTermbaseActionTypes

export const termbasesReducer = (
  state: ITermbaseState = initialState,
  action: TAction
): ITermbaseState => {
  return produce(state, draft => {
    switch (action.type) {
      case TermbaseActionTypes.FETCH_TERMBASES:
        fetchTermbases(draft)
        break

      case TermbaseActionTypes.FETCH_TERMBASES_SUCCESS:
        fetchTermbasesSuccess(draft, action)
        break

      case TermbaseActionTypes.FETCH_TERMBASES_FAILURE:
        fetchTermbasesFailure(draft)
        break

      case TermbaseActionTypes.FETCH_TERMBASE_ENTRIES:
        fetchTermbaseEntries(draft)
        break

      case TermbaseActionTypes.FETCH_TERMBASE_ENTRIES_SUCCESS:
        fetchTermbaseEntriesSuccess(draft, action)
        break

      case TermbaseActionTypes.FETCH_TERMBASE_ENTRIES_FAILURE:
        fetchTermbaseEntriesFailure(draft)
        break

      case TermbaseActionTypes.CREATE_TERMBASE:
      case TermbaseActionTypes.UPDATE_TERMBASE:
      case TermbaseActionTypes.DELETE_TERMBASE:
      case TermbaseActionTypes.UPDATE_TERMBASE_ENTRY:
      case TermbaseActionTypes.ADD_TERMBASE_ENTRY:
      case TermbaseActionTypes.DELETE_TERMBASE_ENTRY:
        startModifying(draft)
        break

      case TermbaseActionTypes.CREATE_TERMBASE_SUCCESS:
      case TermbaseActionTypes.CREATE_TERMBASE_FAILURE:
      case TermbaseActionTypes.UPDATE_TERMBASE_SUCCESS:
      case TermbaseActionTypes.UPDATE_TERMBASE_FAILURE:
      case TermbaseActionTypes.DELETE_TERMBASE_SUCCESS:
      case TermbaseActionTypes.DELETE_TERMBASE_FAILURE:
      case TermbaseActionTypes.UPDATE_TERMBASE_ENTRY_SUCCESS:
      case TermbaseActionTypes.UPDATE_TERMBASE_ENTRY_FAILURE:
      case TermbaseActionTypes.ADD_TERMBASE_ENTRY_SUCCESS:
      case TermbaseActionTypes.ADD_TERMBASE_ENTRY_FAILURE:
      case TermbaseActionTypes.DELETE_TERMBASE_ENTRY_SUCCESS:
      case TermbaseActionTypes.DELETE_TERMBASE_ENTRY_FAILURE:
        stopModifying(draft)
        break

      case TermbaseActionTypes.SET_ACTIVE_TERMBASE_ID:
        setActiveTermbaseId(draft, action)
        break

      case TermbaseActionTypes.SET_ACTIVE_PAGE:
        setActivePage(draft, action)
        break

      case TermbaseActionTypes.RESET_TERMBASE_ENTRIES:
        resetTermbaseEntries(draft)
        break

      case TermbaseActionTypes.SET_SEARCH_TERM:
        setSearchTerm(draft, action)
        break

      default:
        break
    }
  })
}

const fetchTermbases = (draft: Draft<ITermbaseState>) => {
  draft.loadingTermbases = true
}

function isIFetchTermbasesSuccessAction(arg: any): arg is IActionSuccess<IFetchTermbasesSuccessResponseBody> {
  return arg.type === TermbaseActionTypes.FETCH_TERMBASES_SUCCESS
}

const fetchTermbasesSuccess = (draft: Draft<ITermbaseState>, action: TAction) => {
  if (!isIFetchTermbasesSuccessAction(action)) { return }

  draft.termbases = map(action.payload!.data, data => {
    return ({
      id: parseInt(data.id, 10),
      attributes: data.attributes
    })
  })
  draft.allFullnameLanguages = action.payload!.allFullnameLanguages
  draft.loadingTermbases = false
}

const fetchTermbasesFailure = (draft: Draft<ITermbaseState>) => {
  draft.loadingTermbases = false
}

const fetchTermbaseEntries = (draft: Draft<ITermbaseState>) => {
  draft.loadingTermbaseEntries = true
}

function isIFetchTermbaseEntriesSuccessAction(arg: any): arg is IActionSuccess<IFetchTermbaseEntriesSuccessResponseBody> {
  return arg.type === TermbaseActionTypes.FETCH_TERMBASE_ENTRIES_SUCCESS
}

const fetchTermbaseEntriesSuccess = (draft: Draft<ITermbaseState>, action: TAction) => {
  if (!isIFetchTermbaseEntriesSuccessAction(action)) { return }

  draft.termbaseEntries = map(action.payload!.data, data => {
    return ({
      id: parseInt(data.id, 10),
      attributes: data.attributes
    })
  })
  draft.totalPages = action.payload!.totalPages
  draft.totalTermbaseEntryCount = action.payload!.totalCount
  draft.loadingTermbaseEntries = false
}

const fetchTermbaseEntriesFailure = (draft: Draft<ITermbaseState>) => {
  draft.loadingTermbaseEntries = false
}

const startModifying = (draft: Draft<ITermbaseState>) => {
  draft.modifying = true
}

const stopModifying = (draft: Draft<ITermbaseState>) => {
  draft.modifying = false
}

function isISetActiveTermbaseIdAction(arg: any): arg is IAction<ISetActiveTermbaseIdPayload> {
  return arg.type === TermbaseActionTypes.SET_ACTIVE_TERMBASE_ID
}

const setActiveTermbaseId = (draft: Draft<ITermbaseState>, action: TAction) => {
  if (!isISetActiveTermbaseIdAction(action)) { return }

  draft.activeTermbaseId = action.payload.activeTermbaseId
}

function isISetActivePageAction(arg: any): arg is IAction<ISetActivePagePayload> {
  return arg.type === TermbaseActionTypes.SET_ACTIVE_PAGE
}

const setActivePage = (draft: Draft<ITermbaseState>, action: TAction) => {
  if (!isISetActivePageAction(action)) { return }

  draft.activePage = action.payload.activePage
}

const resetTermbaseEntries = (draft: Draft<ITermbaseState>) => {
  draft.termbaseEntries = []
}

function isISetSearchTermAction(arg: any): arg is IAction<ISetSearchTermPayload> {
  return arg.type === TermbaseActionTypes.SET_SEARCH_TERM
}

const setSearchTerm = (draft: Draft<ITermbaseState>, action: TAction) => {
  if (!isISetSearchTermAction(action)) { return }

  draft.searchTerm = action.payload.searchTerm
}
