import { IAction } from '@Root/types'
import { put, takeEvery } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects' // tslint:disable-line:no-duplicate-imports
import toastr from 'toastr'
import Analytics from '@Utils/Analytics'
import Authorization from '@Utils/Authorization'
import { notifyGTMAboutExternalSignUp } from '@Utils/NotifyGTMAboutExternalLoginAndSignup'

import Logger from '@Utils/Logger'
import router from '@Site/Router'

import {
  submitSurveyFailure,
  submitSurveySuccess
} from './actions'

import {
  ISurveyData,
  SurveyActionTypes,
} from './types'

import * as API from './api'

const call: any = Eff.call

function* submitSurveySaga(action: IAction<ISurveyData>) {
  if (window.Checksub.cookies.get('onboarding') === 'processed') {
    window.location.href = '/'
    toastr.info('We have already stored your answers before', 'No need to answer again')
  } else {
    try {
      yield call(API.submitSurvey, action.payload)
      yield put(submitSurveySuccess())

      window.Checksub.cookies.set('onboarding', 'processed', { path: '/' })

      Analytics.track('sign_up')

      if (Authorization.isSignedUpThroughOauth()) {
        notifyGTMAboutExternalSignUp()
      } else {
        Analytics.trackGTMSignUpLogin('sign_up', 'email')
      }

      yield call(router.navigate, '/onboarding/check_email', { replace: true })
    } catch (e) {
      Logger.error(e, 'Request has failed')
      yield put(submitSurveyFailure())
    }
  }
}

function* surveySagas() {
  yield takeEvery(SurveyActionTypes.SUBMIT_SURVEY, submitSurveySaga)
}

export default surveySagas
