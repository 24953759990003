import { fork, all } from 'redux-saga/effects'

import projectVersionsListSagas from '@SiteContainers/ProjectVersionsList/sagas'
import usersSignUpSagas from '@SitePages/UsersSignUp/sagas'
import usersSignInSagas from '@SitePages/UsersSignIn/sagas'
import usersConfirmationNewSagas from '@SitePages/UsersConfirmationNew/sagas'
import updatePasswordEditSagas from '@SitePages/UsersPasswordEdit/sagas'
import usersPasswordNewSagas from '@SitePages/UsersPasswordNew/sagas'
import createSubscriptionSagas from '@SiteModules/Subscription/sagas'
import projectVersionSettingsSagas from '@SitePages/ProjectVersionSettings/sagas'
import projectVersionSettingsTtsSagas from '@SitePages/ProjectVersionSettingsTts/sagas'
import externalTokenSagas from '@SiteContainers/ExternalTokenPopup/sagas'
import deleteProjectVersionSagas from '@SiteContainers/DeleteProjectVersionPopup/sagas'
import deleteLabelSagas from '@SiteContainers/DeleteLabelPopup/sagas'
import importSrtSagas from '@SiteContainers/ImportSrtPopup/sagas'
import surveySagas from '@SitePages/Onboarding/Survey/sagas'
import vocabularySagas from '@SiteModules/Vocabulary/sagas'
import guidelineSagas from '@SiteModules/Guideline/sagas'
import termbaseSagas from '@SiteModules/Termbase/sagas'
import usersSagas from '@SiteModules/User/sagas'
import labelSagas from '@SiteModules/Label/sagas'
import projectSagas from '@SiteModules/Project/sagas'
import elementSagas from '@SiteModules/Element/sagas'
import channelsTableSagas from '@SiteContainers/ChannelsTable/sagas'
import assetsTableSagas from '@SiteContainers/AssetsTable/sagas'
import themesSagas from '@SiteModules/Theme/sagas'
import renameLabelPopupSagas from '@SiteContainers/RenameLabelPopup/sagas'
import demoSagas from '@SiteModules/Demo/sagas'
import exportationSaga from '@SiteModules/Exportation/sagas'
import processingSaga from '@SitePages/Processing/sagas'

export default function* sagas() {
  yield all([
    fork(labelSagas),
    fork(usersSagas),
    fork(surveySagas),
    fork(projectSagas),
    fork(elementSagas),
    fork(importSrtSagas),
    fork(vocabularySagas),
    fork(guidelineSagas),
    fork(termbaseSagas),
    fork(assetsTableSagas),
    fork(deleteLabelSagas),
    fork(usersSignUpSagas),
    fork(usersSignInSagas),
    fork(externalTokenSagas),
    fork(channelsTableSagas),
    fork(assetsTableSagas),
    fork(themesSagas),
    fork(renameLabelPopupSagas),
    fork(usersPasswordNewSagas),
    fork(updatePasswordEditSagas),
    fork(createSubscriptionSagas),
    fork(projectVersionsListSagas),
    fork(deleteProjectVersionSagas),
    fork(usersConfirmationNewSagas),
    fork(projectVersionSettingsSagas),
    fork(projectVersionSettingsTtsSagas),
    fork(demoSagas),
    fork(exportationSaga),
    fork(processingSaga),
  ])
}
