import { IUserAttributes } from '@SiteModules/User/types'

import {
  IUserSignInState,
  TSignInActionTypes,
  ISignInSuccessAction,
  SignInFormActionTypes
} from './types'

const initialUser: IUserAttributes = {
  name: '',
  email:  '',
  lastName:  '',
  role: '',
  workspaceId: -1,
  onboardingState: '',
  maxFileSize:  null,
  confirmedEmail: true,
  remainingElementStorageSpace: 10, // Small default value so that no warning shows up initially
  maxElementStorageSpace: 0,
  usedElementStorageSpace: 0,
  hasActiveElements: false,
  canGetTrial: false,
  isNewUser: false,
  usingPersonalSubscription: false,
  relevantAlert: null,
  exportingSubtitleIds: [],
  exportingProjectVersionIds: [],
  availableTotalCredits: 0,
}

export const initialState: IUserSignInState = {
  user: initialUser,
  errors: [],
  loading: false
}

export const userSignInReducer = (
  state: IUserSignInState = initialState,
  action: TSignInActionTypes
): IUserSignInState => {
  switch (action.type) {
    case SignInFormActionTypes.SIGN_IN: return signIn(state)
    case SignInFormActionTypes.SIGN_IN_SUCCESS: return signInSuccess(state, action)
    case SignInFormActionTypes.SIGN_IN_FAILURE: return signInFailure(state)

    default: return state
  }
}

const signIn = (state: IUserSignInState): IUserSignInState => {
  return { ...state, loading: true }
}

function isSignInSuccessAction(arg: any): arg is ISignInSuccessAction {
  return arg.payload != null
}

const signInSuccess = (state: IUserSignInState, action: TSignInActionTypes): IUserSignInState => {
  let userAttributes = initialUser

  if (isSignInSuccessAction(action)) {
    const user = action.payload
    userAttributes = user.attributes
  }

  return { ...state, user: userAttributes, loading: false }
}

const signInFailure = (state: IUserSignInState): IUserSignInState => {
  return { ...state, loading: false }
}
