import { produce, Draft } from 'immer'
import { IActionSuccess, TActionTypes } from '@Root/types'
import map from 'lodash/map'

import {
  IThemeState,
  ThemeActionTypes,
  TThemeActionTypes,
  IFetchThemesSuccessResponseBody,
} from './types'

export const initialState: IThemeState = {
  themes: [],
  setThemePopupOpen: false,
  loadingThemes: false
}

type TAction = TActionTypes | TThemeActionTypes

export const themesReducer  = (
  state: IThemeState = initialState,
  action: TAction
): IThemeState => {
  return produce(state, draft => {
    switch (action.type) {
      case ThemeActionTypes.FETCH_THEMES:
        fetchThemes(draft)
        break

      case ThemeActionTypes.FETCH_THEMES_SUCCESS:
        fetchThemesSuccess(draft, action)
        break

      case ThemeActionTypes.FETCH_THEMES_FAILURE:
        fetchThemesFailure(draft)
        break

      case ThemeActionTypes.OPEN_SET_THEME_POPUP:
        openSetThemePopup(draft)
        break

      case ThemeActionTypes.CLOSE_SET_THEME_POPUP:
        closeSetThemePopup(draft)
        break

      default:
        break
    }
  })
}

const openSetThemePopup = (draft: Draft<IThemeState>) => {
  draft.setThemePopupOpen = true
}

const closeSetThemePopup = (draft: Draft<IThemeState>) => {
  draft.setThemePopupOpen = false
}

const fetchThemes = (draft: Draft<IThemeState>) => {
  draft.loadingThemes = true
}

function isIFetchThemesSuccessAction(arg: any): arg is IActionSuccess<IFetchThemesSuccessResponseBody> {
  return arg.type === ThemeActionTypes.FETCH_THEMES_SUCCESS
}

const fetchThemesSuccess = (draft: Draft<IThemeState>, action: TAction) => {
  if (!isIFetchThemesSuccessAction(action)) { return }

  draft.themes= map(action.payload!.data, data => {
    return ({
      id: parseInt(data.id, 10),
      attributes: data.attributes
    })
  })
  draft.loadingThemes = false
}

const fetchThemesFailure = (draft: Draft<IThemeState>) => {
  draft.loadingThemes = false
}
