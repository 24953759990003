import { produce, Draft } from 'immer'
import { IActionSuccess, TActionTypes } from '@Root/types'
import map from 'lodash/map'

import {
  IVocabularyState,
  VocabularyActionTypes,
  TVocabularyActionTypes,
  IFetchVocabulariesSuccessResponseBody,
} from './types'

export const initialState: IVocabularyState = {
  vocabularies: [],
  loading: false,
  modifying: false
}

type TAction = TActionTypes | TVocabularyActionTypes

export const vocabulariesReducer = (
  state: IVocabularyState = initialState,
  action: TAction
): IVocabularyState => {
  return produce(state, draft => {
    switch (action.type) {
      case VocabularyActionTypes.FETCH_VOCABULARIES:
        fetchVocabularies(draft)
        break

      case VocabularyActionTypes.FETCH_VOCABULARIES_SUCCESS:
        fetchVocabulariesSuccess(draft, action)
        break

      case VocabularyActionTypes.FETCH_VOCABULARIES_FAILURE:
        fetchVocabulariesFailure(draft)
        break

      case VocabularyActionTypes.CREATE_VOCABULARY:
      case VocabularyActionTypes.UPDATE_VOCABULARY:
      case VocabularyActionTypes.DELETE_VOCABULARY:
        startModifying(draft)
        break

      case VocabularyActionTypes.CREATE_VOCABULARY_SUCCESS:
      case VocabularyActionTypes.CREATE_VOCABULARY_FAILURE:
      case VocabularyActionTypes.UPDATE_VOCABULARY_SUCCESS:
      case VocabularyActionTypes.UPDATE_VOCABULARY_FAILURE:
      case VocabularyActionTypes.DELETE_VOCABULARY_SUCCESS:
      case VocabularyActionTypes.DELETE_VOCABULARY_FAILURE:
        stopModifying(draft)
        break

      default:
        break
    }
  })
}

const fetchVocabularies = (draft: Draft<IVocabularyState>) => {
  draft.loading = true
}

function isIFetchVocabulariesSuccessAction(arg: any): arg is IActionSuccess<IFetchVocabulariesSuccessResponseBody> {
  return arg.type === VocabularyActionTypes.FETCH_VOCABULARIES_SUCCESS
}

const fetchVocabulariesSuccess = (draft: Draft<IVocabularyState>, action: TAction) => {
  if (!isIFetchVocabulariesSuccessAction(action)) { return }

  draft.vocabularies = map(action.payload!.data, data => {
    return ({
      id: parseInt(data.id, 10),
      attributes: data.attributes
    })
  })
  draft.loading = false
}

const fetchVocabulariesFailure = (draft: Draft<IVocabularyState>) => {
  draft.loading = false
}

const startModifying = (draft: Draft<IVocabularyState>) => {
  draft.modifying = true
}

const stopModifying = (draft: Draft<IVocabularyState>) => {
  draft.modifying = false
}
