import { put, takeEvery } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects' // tslint:disable-line:no-duplicate-imports
import toastr from 'toastr'

// TODO: !!
import 'toastr/build/toastr.css'

import Logger from '@Utils/Logger'

import {
  IAction,
  ISuccessResponse
} from '@Root/types'

import {
  createProjectSuccess,
  createProjectFailure,
} from './actions'

import {
  ProjectActionTypes,
  ICreateProjectSuccessResponseBody
} from './types'

import * as API from './api'

const call: any = Eff.call

function* createProjectSaga(_action: IAction) {
  try {
    const response: ISuccessResponse<ICreateProjectSuccessResponseBody> = yield call(API.createProject)
    const projectId = response.data.data[0].attributes.projectId
    const projectVersionId = response.data.data[0].id
    yield put(createProjectSuccess())
    toastr.success('', 'Project created')
    window.location.href = `/projects/${projectId}/project_versions/${projectVersionId}`
  } catch (e) {
    Logger.error(e, 'Project could not be created')
    yield put(createProjectFailure())
  }
}

function* projectSagas() {
  yield takeEvery(ProjectActionTypes.CREATE_PROJECT, createProjectSaga)
}

export default projectSagas
