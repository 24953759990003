import { Action } from 'redux'
import { put, takeEvery } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects' // tslint:disable-line:no-duplicate-imports
import { getUserInfo } from '@SiteModules/User/actions'
import router from '@Site/Router'
import Analytics from '@Utils/Analytics'
import Logger from '@Utils/Logger'

import {
  ISuccessResponse,
} from '@Root/types'

import {
  signInFailure,
  signInSuccess
} from './actions'

import {
  SignInFormActionTypes
} from './types'

import * as API from './api'

// TODO: remove it later...
interface IAction extends Action {
  readonly payload: object
}

const call: any = Eff.call

function* signInUserSaga(action: IAction): Generator<any, any, any> {
  try {
    const response = 'useSso' in action.payload ?
      yield call(API.ssoSignIn, action.payload) :
      yield call(API.signInUser, action.payload)
    const { data } = response.data
    yield put(signInSuccess(data))

    Analytics.trackGTMSignUpLogin('login', 'email')

    yield put(getUserInfo())

    window.Checksub.token = window.Checksub.cookies.get('token')
    yield call(router.navigate, '/', { replace: true })
  } catch (e) {
    Logger.error(e, 'Log-in failed')
    yield put(signInFailure())
  }
}

interface IRedirectUri {
  readonly redirect_uri: string
}

function* ssoSignInUserSaga(action: IAction): Generator<any, any, any> {
  try {
    const response: ISuccessResponse<IRedirectUri> = yield call(API.ssoSignIn, action.payload)
    window.location.href = response.data.redirect_uri
  } catch (e) {
    Logger.error(e, 'Log-in failed')
    yield put(signInFailure())
  }
}

function* signInFormSagas() {
  yield takeEvery(SignInFormActionTypes.SIGN_IN, signInUserSaga)
  yield takeEvery(SignInFormActionTypes.SSO_SIGN_IN, ssoSignInUserSaga)
}

export default signInFormSagas
