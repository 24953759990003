import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects' // tslint:disable-line:no-duplicate-imports
import Logger from '@Utils/Logger'
import toastr from 'toastr'

import {
  IAction,
} from '@Root/types'

import {
  getElementsSuccess,
  getElementsFailure,
  getAffectedProjectVersionsSuccess,
  getAffectedProjectVersionsFailure,
  deleteElementSuccess,
  deleteElementFailure,
  getElements
} from './actions'

import {
  IGetElementsPayload,
  IDeleteElementPayload,
  AssetsActionTypes,
  IGetElementsSuccessResponse,
  IGetAffectedProjectVersionsSuccessResponse,
  IGetAffectedProjectVersionsPayload,
} from './types'

import * as API from './api'

const call: any = Eff.call

function* getElementsSaga(_action: Action): Generator<any, any, any> {
  try {
    const state = yield select()
    const payload: IGetElementsPayload = {
      page: state.assetsTable.currentPage
    }
    const response: IGetElementsSuccessResponse = yield call(API.getElements, payload)

    const { data, totalCount, totalPages } = response.data
    const successPayload = { data, totalCount, totalPages }

    yield put(getElementsSuccess(successPayload))
  } catch (e) {
    Logger.error(e, 'Assets could not be loaded')
    yield put(getElementsFailure())
  }
}

function* getAffectedProjectVersionsSaga(action: IAction<IGetAffectedProjectVersionsPayload>): Generator<any, any, any> {
  try {
    const response: IGetAffectedProjectVersionsSuccessResponse = yield call(API.getAffectedProjectVersions, action.payload)

    const { data } = response.data
    const successPayload = { data, elementId: action.payload.elementId }

    yield put(getAffectedProjectVersionsSuccess(successPayload))
  } catch (e) {
    Logger.error(e, 'Affected projects could not be loaded')
    yield put(getAffectedProjectVersionsFailure())
  }
}


function* deleteElementSaga(action: IAction<IDeleteElementPayload>): Generator<any, any, any> {
  try {
    yield call(API.deleteElement, action.payload)

    yield put(deleteElementSuccess())
    toastr.success('', 'Asset deleted')
    yield put(getElements())
  } catch (e) {
    Logger.error(e, 'Asset could not be deleted')
    yield put(deleteElementFailure())
  }
}

function* assetsTableSagas() {
  yield takeLatest(AssetsActionTypes.GET_ELEMENTS, getElementsSaga)
  yield takeLatest(AssetsActionTypes.INCREASE_PAGE_COUNT, getElementsSaga)
  yield takeLatest(AssetsActionTypes.DECREASE_PAGE_COUNT, getElementsSaga)
  yield takeLatest(AssetsActionTypes.GET_AFFECTED_PROJECT_VERSIONS, getAffectedProjectVersionsSaga)
  yield takeLatest(AssetsActionTypes.DELETE_ELEMENT, deleteElementSaga)
}

export default assetsTableSagas
