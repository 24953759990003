import { produce, Draft } from 'immer'
import without from 'lodash/without'
import smartMerge from '@Utils/SmartMerge'

import {
  ProjectVersionActionTypes,
  IProjectVersionsListState,
  IOpenExportationAction,
  IOpenRateSubtitle,
  IOpenExternalToken,
  IOpenImportSrtAction,
  TProjectVersionsListActionTypes,
  IFetchProjectVersionsSuccessAction,
  IUpdateProjectVersionsSuccessAction,
  IChangeSelectedProjectVersionsAction,
} from './types'

import {
  DeleteProjectVersionActionTypes
} from '@SiteContainers/DeleteProjectVersionPopup/types'

import {
  TProjectVersionSettingsAction,
  ProjectVersionSettingsActionTypes,
} from '@SitePages/ProjectVersionSettings/types'

import {
  LabelActionTypes,
} from '@SiteModules/Label/types'

import {
  TUserActions,
} from '@SiteModules/User/types'

import {
  ProjectActionTypes
} from '@SiteModules/Project/types'

import {
  ElementActionTypes,
  TElementAction,
  ISubmitUrlsSuccessAction,
  ICreateElementSuccessAction,
} from '@SiteModules/Element/types'

export const initialState: IProjectVersionsListState = {
  projectVersions: [],
  subtitles: [],
  loading: false,
  subtitleId: -1,
  subtitleIds: [],
  currentPage: 1,
  totalCount: 0,
  totalPages: 1,
  editingProjectVersionIds: [],
  selectedProjectVersions: [],
  openImportSrtPopup: false,
  subtitleHasSpeakers: false,
  openExportationPopup: false,
  openRateSubtitlePopup: false,
  openExternalTokenPopup: false,
  openDeleteProjectVersionPopup: false,
  editingVideoBelongsToManualProject: false,
}

type TAction = TProjectVersionsListActionTypes | TProjectVersionSettingsAction | TUserActions | TElementAction

export const projectVersionsListReducer = (
  state: IProjectVersionsListState = initialState,
  action: TAction,
): IProjectVersionsListState => {
  return produce(state, draft => {
    switch (action.type) {
      case ProjectVersionActionTypes.CLOSE_POPUP:
        closePopup(draft)
        break

      case ProjectVersionActionTypes.LOAD_PROJECT_VERSIONS:
        loadProjectVersions(draft)
        break

      case ProjectVersionActionTypes.FETCH_PROJECT_VERSIONS_SUCCESS:
        fetchProjectVersionsSuccess(draft, action)
        break

      case ProjectVersionActionTypes.FETCH_PROJECT_VERSIONS_FAILURE:
        projectVersionsListFailure(draft)
        break

      case ProjectVersionActionTypes.UPDATE_PROJECT_VERSIONS_SUCCESS:
        updateProjectVersionsSuccess(draft, action)
        break

      case ProjectVersionActionTypes.UPDATE_PROJECT_VERSIONS_FAILURE:
        projectVersionsListFailure(draft)
        break

      case ProjectVersionActionTypes.OPEN_IMPORT_SRT_POPUP:
        openImportSrtPopup(draft, action)
        break

      case ProjectVersionActionTypes.OPEN_DELETE_PROJECT_VERSION_POPUP:
        openDeleteProjectVersionPopup(draft)
        break

      case ProjectVersionActionTypes.OPEN_EXTERNAL_TOKEN_POPUP:
        openExternalTokenPopup(draft, action)
        break

      case ProjectVersionActionTypes.OPEN_EXPORTATION_POPUP:
        openExportationPopup(draft, action)
        break

      case ProjectVersionActionTypes.OPEN_RATE_SUBTITLE_POPUP:
        openRateSubtitlePopup(draft, action)
        break

      case ProjectVersionActionTypes.CHANGE_SELECTED_PROJECT_VERSIONS:
        changeSelectedProjectVersions(draft, action)
        break

      case ProjectVersionActionTypes.INCREASE_PAGE_COUNT:
        increasePageCount(draft)
        break

      case ProjectVersionActionTypes.DECREASE_PAGE_COUNT:
        decreasePageCount(draft)
        break

      case ProjectVersionSettingsActionTypes.SUBMIT_PROJECT_VERSION_UPDATE:
        resetProjectVersionSelectionAndPageCount(draft)
        break

      case DeleteProjectVersionActionTypes.DELETE_PROJECT_VERSION_SUCCESS:
        resetProjectVersionSelectionAndPageCount(draft)
        break

      case LabelActionTypes.CHANGE_SELECTED_LABELS:
        resetProjectVersionSelectionAndPageCount(draft)
        break

      case LabelActionTypes.RESET_LABEL_SELECTION:
        resetProjectVersionSelectionAndPageCount(draft)
        break

      case ProjectActionTypes.CREATE_PROJECT:
        resetProjectVersionSelectionAndPageCount(draft)
        break

      case ElementActionTypes.CREATE_ELEMENT_SUCCESS:
        updateProjectVersionsList(draft, action)
        break

      case ElementActionTypes.SUBMIT_URLS_SUCCESS:
        updateProjectVersionsList(draft, action)
        break

      default:
        break
    }
  })
}

const loadProjectVersions = (draft: Draft<IProjectVersionsListState>) => {
  draft.loading = true
}

function isIOpenExternalToken(arg: any): arg is IOpenExternalToken {
  return arg.payload && arg.payload.subtitleId != null
}

const openExternalTokenPopup = (draft: Draft<IProjectVersionsListState>, action: TAction) => {
  if (isIOpenExternalToken(action)) {
    draft.subtitleId = action.payload.subtitleId
  }
  draft.openExternalTokenPopup = true
}

function isIOpenExportation(arg: any): arg is IOpenExportationAction {
  return arg.payload != null && arg.payload.subtitleIds != null
}

const openExportationPopup = (draft: Draft<IProjectVersionsListState>, action: TAction) => {
  draft.subtitleIds = []

  if (isIOpenExportation(action)) {
    draft.subtitleIds = draft.subtitleIds.concat(action.payload.subtitleIds)
  }

  draft.openExportationPopup = true
}

function isIOpenRateSubtitle(arg: any): arg is IOpenRateSubtitle {
  return arg.payload && arg.payload.subtitleId != null
}

const openRateSubtitlePopup = (draft: Draft<IProjectVersionsListState>, action: TAction) => {
  draft.subtitleId = -1

  if (isIOpenRateSubtitle(action)) {
    draft.subtitleId = action.payload.subtitleId
  }

  draft.openRateSubtitlePopup = true
}

function isIOpenImportSrtPopup(arg: any): arg is IOpenImportSrtAction {
  return arg.payload && arg.payload.subtitleId != null && arg.payload.projectVersionId != null
}

const openImportSrtPopup = (draft: Draft<IProjectVersionsListState>, action: TAction) => {
  draft.subtitleId = -1
  draft.editingProjectVersionIds = []

  if (isIOpenImportSrtPopup(action)) {
    draft.subtitleId = action.payload.subtitleId
    draft.editingProjectVersionIds = [action.payload.projectVersionId]
  }

  draft.openImportSrtPopup = true
}

const openDeleteProjectVersionPopup = (draft: Draft<IProjectVersionsListState>) => {
  draft.openDeleteProjectVersionPopup = true
}

const fetchProjectVersionsSuccess = (draft: Draft<IProjectVersionsListState>, action: TAction) => {
  draft.projectVersions = []
  draft.subtitles = []
  draft.totalCount = 0
  draft.totalPages = 1

  if (isIFetchProjectVersionsSuccessAction(action)) {
    draft.projectVersions = action.projectVersions
    draft.subtitles = action.subtitles
    draft.totalCount = action.totalCount
    draft.totalPages = action.totalPages
  }

  draft.loading = false
}

function isIFetchProjectVersionsSuccessAction(arg: any): arg is IFetchProjectVersionsSuccessAction {
  return arg.projectVersions != null
}

const projectVersionsListFailure = (draft: Draft<IProjectVersionsListState>) => {
  draft.loading = false
}

function isIUpdateProjectVersionsSuccessAction(arg: any): arg is IUpdateProjectVersionsSuccessAction {
  return arg.payload.projectVersions.data != null && arg.payload.subtitles.data != null
}

const updateProjectVersionsSuccess = (draft: Draft<IProjectVersionsListState>, action: TAction) => {
  if (isIUpdateProjectVersionsSuccessAction(action)) {
    const projectVersionsToUpdate = action.payload.projectVersions.data
    const subtitlesToUpdate = action.payload.subtitles.data

    draft.projectVersions = smartMerge(draft.projectVersions, projectVersionsToUpdate)
    draft.subtitles = smartMerge(draft.subtitles, subtitlesToUpdate)
  }

  draft.loading = false
}

const closePopup = (draft: Draft<IProjectVersionsListState>) => {
  draft.subtitleId = -1
  draft.openImportSrtPopup = false
  draft.openExportationPopup = false
  draft.openRateSubtitlePopup = false
  draft.openExternalTokenPopup = false
  draft.openDeleteProjectVersionPopup = false
  draft.editingProjectVersionIds = []
}

function isIChangeSelectedProjectVersions(arg: any): arg is IChangeSelectedProjectVersionsAction {
  return arg.payload.projectVersionId != null && arg.payload.selectProjectVersion != null
}

const changeSelectedProjectVersions = (draft: Draft<IProjectVersionsListState>, action: TAction) => {
  if (isIChangeSelectedProjectVersions(action)){
    const { selectProjectVersion, projectVersionId } = action.payload

    if (selectProjectVersion) {
      draft.selectedProjectVersions.push(projectVersionId)
    } else {
      draft.selectedProjectVersions = without(draft.selectedProjectVersions, projectVersionId)
    }
  }
}

const resetProjectVersionSelectionAndPageCount = (draft: Draft<IProjectVersionsListState>) => {
  draft.selectedProjectVersions = []
  draft.currentPage = 1
}

const increasePageCount = (draft: Draft<IProjectVersionsListState>) => {
  draft.currentPage += 1
  draft.selectedProjectVersions = []
}

const decreasePageCount = (draft: Draft<IProjectVersionsListState>) => {
  draft.currentPage -= 1
  draft.selectedProjectVersions = []
}

function isICreateElementSuccessAction(arg: any): arg is ICreateElementSuccessAction {
  return arg.payload.data != null
}

function isISubmitUrlsSuccessAction(arg: any): arg is ISubmitUrlsSuccessAction {
  return arg.payload.data != null
}

const updateProjectVersionsList = (draft: Draft<IProjectVersionsListState>, action: TAction) => {
  if (isICreateElementSuccessAction(action) || isISubmitUrlsSuccessAction(action)) {
    const newProjectVersions = action.payload.data
    draft.projectVersions = [...newProjectVersions, ...draft.projectVersions]
  }
}
