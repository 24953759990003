import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import rootReducer from './rootReducer'
import sagas from './sagas'

export default function configureChecksubStore() {
  const devMode = process.env.NODE_ENV === 'development'
  const onStaging = window?.location?.hostname === 'staging-checksub.herokuapp.com'
  const enableDevTools = devMode || onStaging
  const sagaMiddleware = createSagaMiddleware()

  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => {
      const middlewares = getDefaultMiddleware()

      middlewares.push(sagaMiddleware)

      if (enableDevTools) {
        const { logger } = require('redux-logger')

        middlewares.push(logger)
      }

      return middlewares
    },
    devTools: enableDevTools
  })

  sagaMiddleware.run(sagas)

  return store
}
