import axios, { AxiosRequestConfig } from 'axios'

import {
  IGetExportationsForDownloadPayload,
} from '@EditorModules/Exportation/types'

export function getExportationsForDownload(data: IGetExportationsForDownloadPayload) {
  const config: AxiosRequestConfig = {
    headers: {
      'X-CSRF-Token': window.Checksub.token
    },
    responseType: 'json'
  }

  if (data.subtitleId) {
    return axios.get(`/api/v1/subtitles/${data.subtitleId}/show_exportations_for_download`, config)
  } else if (data.projectVersionId) {
    return axios.get(`/api/v1/project_versions/${data.projectVersionId}/show_exportations_for_download`, config)
  }
}
