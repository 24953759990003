import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import Cookies from 'universal-cookie'
import toastr from 'toastr'

import ChecksubApp from '@Site/ChecksubApp'
import configureChecksubStore from '@Site/configureChecksubStore'

import 'toastr/build/toastr.css'

import './site.scss'

const store = configureChecksubStore()

const rootEl = document.getElementById('root') as HTMLDivElement
const csrfTokenEl = document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement
const token = csrfTokenEl.content

const cookies = new Cookies()

toastr.options.closeButton = true

interface IChecksub {
  token: string
  readonly email?: string
  readonly s3Bucket: string
  readonly stripePublishableKey: string
  readonly essentialCostForMinute: number
  readonly cookies: Cookies
  readonly tooltipDelay: number
  readonly termbaseLanguageColors: string[]
}

declare global {
  interface Window {
    Checksub: IChecksub
    gtag: typeof gtag
    dataLayer: any
    readonly Rollbar: any
  }
}

window.Checksub = {
  ...window.Checksub,
  token,
  cookies,
  tooltipDelay: 500,
  termbaseLanguageColors: [
    '#43c78f', // $text_success_default ($dark_green_900)
    '#ca9ffc', // $text_brand_default ($dark_purple_900)
    '#ffb558', // $text_warning_default ($dark_orange_1000)
    '#72b7f9', // $text_accent_default ($dark_blue_900)
    '#8159b4', // moderate violet
    '#e48c72', // soft red
    '#c15a87', // moderate pink
    '#bbc15a', // moderate yellow
    '#c1945a', // moderate orange
    '#a9a9a9', // gray
    '#ff92d2', // very light pink
    '#99cfe0', // very soft cyan
  ],
}

const root = createRoot(rootEl)
root.render(
  <Provider store={store}>
    <ChecksubApp />
  </Provider>
)
