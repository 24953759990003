import { put, takeEvery } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects' // tslint:disable-line:no-duplicate-imports
import showConfetti from '@Utils/ShowConfettiAnimation'

import Logger from '@Utils/Logger'
import { IAction, ISuccessResponse } from '@Root/types'

import {
  closeSetThemePopup,
  fetchThemesSuccess,
  fetchThemesFailure,
} from './actions'

import {
  ThemeActionTypes,
  ISetThemePayload,
  IFetchThemesSuccessResponseBody,
} from './types'

import * as API from './api'

const call: any = Eff.call

const SHORT_TIMESPAN = 2000 // ms

function* setThemeSaga(action: IAction): Generator<any, any, any> {
  const payload = action.payload as ISetThemePayload

  try {
    yield call(API.setTheme, payload)
    yield put(closeSetThemePopup())

    showConfetti()

    setTimeout(() => {
      const isForOneProjectVersion = payload.projectVersionIds.length === 1
      const isNewProject = payload.isNewProject

      window.location.href = (isForOneProjectVersion && isNewProject) ? `/processing?projectVersionId=${payload.projectVersionIds[0]}` : '/'
    }, SHORT_TIMESPAN)
  } catch (e) {
    Logger.error(e, 'Request could not be submitted')
  }
}

function* fetchThemesSaga() {
  try {
    const response: ISuccessResponse<IFetchThemesSuccessResponseBody> = yield call(API.fetchThemes)
    const payloadResponse = response.data
    yield put(fetchThemesSuccess(payloadResponse))
  } catch (e) {
    Logger.error(e, 'Themes could not be loaded')
    yield put(fetchThemesFailure())
  }
}

function* themeSagas() {
  yield takeEvery(ThemeActionTypes.SET_THEME, setThemeSaga)
  yield takeEvery(ThemeActionTypes.FETCH_THEMES, fetchThemesSaga)
}

export default themeSagas
