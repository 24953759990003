import { put, takeEvery } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects' // tslint:disable-line:no-duplicate-imports

import Logger from '@Utils/Logger'
import router from '@Site/Router'

import {
  ISuccessResponse,
  IAction,
} from '@Root/types'

import {
  logoutSuccess,
  getUserInfoFailure,
  getUserInfoSuccess,
  getUserStripeInfoFailure,
  getUserStripeInfoSuccess,
  createCustomerPortalSessionFailure,
  getExportingSubtitleIdsSuccess,
  getExportingProjectVersionIdsSuccess,
} from './actions'

import {
  IUserInfo,
  IUserStripeInfo,
  UserActionTypes,
  IConnectExternalChannel,
  IOpenSsoAdminPortal,
  ISetLastViewedAlert,
} from './types'

import {
  IGetExportingSubtitleIdsSuccessPayload,
  IGetExportingProjectVersionIdsSuccessPayload,
} from '@EditorModules/User/types'

import {
  AssetsActionTypes
} from '@SiteContainers/AssetsTable/types'

import * as API from './api'

const call: any = Eff.call

function* logoutSaga(action: IAction<string>) {
  try {
    const { payload } = action
    yield call(API.logout)
    yield put(logoutSuccess())
    window.location.href = payload || '/users/sign_in'
    window.Checksub.token = window.Checksub.cookies.get('token')
  } catch (e) {
    Logger.error(e, 'Logout is failed')
  }
}

function* getUserInfoSaga() {
  try {
    const response: ISuccessResponse<IUserInfo> = yield call(API.getUserInfo)
    const payload: IUserInfo = response.data
    yield put(getUserInfoSuccess(payload))
    window.Checksub.token = window.Checksub.cookies.get('token')

    const { onboardingState } = payload.user.data.attributes
    const shouldRedirectToSurvey = onboardingState === 'survey'

    if (shouldRedirectToSurvey) {
      yield call(router.navigate, '/onboarding', { replace: true })
    }
  } catch (e) {
    Logger.error(e, 'User details cannot be loaded')
    yield put(getUserInfoFailure())
  }
}

function* getUserStripeInfoSaga() {
  try {
    const response: ISuccessResponse<IUserStripeInfo> = yield call(API.getUserStripeInfo)
    const payload: IUserStripeInfo = response.data
    yield put(getUserStripeInfoSuccess(payload))
  } catch (e) {
    Logger.error(e, 'User details cannot be loaded')
    yield put(getUserStripeInfoFailure())
  }
}

interface IRedirectUri {
  readonly redirect_uri: string
}

function* connectExternalChannelSaga(action: IAction<IConnectExternalChannel>) {
  try {
    const response: ISuccessResponse<IRedirectUri> = yield call(API.connectExternalChannel, action.payload)
    window.location.href = response.data.redirect_uri
  } catch (e) {
    Logger.error(e, 'Authentication failed')
  }
}

function* createCustomerPortalSessionSaga(): Generator<any, any, any> {
  try {
    const response: ISuccessResponse<IRedirectUri> = yield call(API.createCustomerPortalSession)
    window.location.href = response.data.redirect_uri
  } catch (e) {
    Logger.error(e, 'Can\'t open the billing')
    yield put(createCustomerPortalSessionFailure())
  }
}

function* openSsoAdminPortalSaga(action: IAction<IOpenSsoAdminPortal>) {
  try {
    const response: ISuccessResponse<IRedirectUri> = yield call(API.openSsoAdminPortal, action.payload)
    window.location.href = response.data.redirect_uri
  } catch (e) {
    Logger.error(e, 'Can\'t open the SSO admin portal')
  }
}

function* setLastViewedAlertSaga(action: IAction<ISetLastViewedAlert>) {
  try {
    yield call(API.setLastViewedAlert, action.payload)
  } catch (e) {
    Logger.error(e, 'Can\'t set last viewed alert')
  }
}

function* getExportingSubtitleIdsSaga() {
  try {
    const response: ISuccessResponse<IGetExportingSubtitleIdsSuccessPayload> = yield call(API.getExportingSubtitleIds)
    const payloadResponse = response.data
    yield put(getExportingSubtitleIdsSuccess(payloadResponse))
  } catch(error) {
    Logger.error(error)
  }
}

function* getExportingProjectVersionIdsSaga() {
  try {
    const response: ISuccessResponse<IGetExportingProjectVersionIdsSuccessPayload> = yield call(API.getExportingProjectVersionIds)
    const payloadResponse = response.data
    yield put(getExportingProjectVersionIdsSuccess(payloadResponse))
  } catch(error) {
    Logger.error(error)
  }
}

function* userSagas() {
  yield takeEvery(UserActionTypes.LOGOUT, logoutSaga)
  yield takeEvery(UserActionTypes.GET_INFO, getUserInfoSaga)
  yield takeEvery(UserActionTypes.GET_STRIPE_INFO, getUserStripeInfoSaga)
  yield takeEvery(UserActionTypes.CREATE_CUSTOMER_PORTAL_SESSION, createCustomerPortalSessionSaga)
  yield takeEvery(UserActionTypes.CONNECT_EXTERNAL_CHANNEL, connectExternalChannelSaga)
  yield takeEvery(UserActionTypes.OPEN_SSO_ADMIN_PORTAL, openSsoAdminPortalSaga)
  yield takeEvery(UserActionTypes.SET_LAST_VIEWED_ALERT, setLastViewedAlertSaga)
  yield takeEvery(UserActionTypes.GET_EXPORTING_SUBTITLE_IDS, getExportingSubtitleIdsSaga)
  yield takeEvery(UserActionTypes.GET_EXPORTING_PROJECT_VERSION_IDS, getExportingProjectVersionIdsSaga)
  yield takeEvery(AssetsActionTypes.DELETE_ELEMENT_SUCCESS, getUserInfoSaga)
}

export default userSagas
