import {
  IAction,
} from '@Root/types'

import {
  IProjectVersionSettings,
  ProjectVersionSettingsActionTypes,
  IProjectVersionSettingsState,
  IProjectVersionSettingsActionTypes,
  IFetchProjectVersionSettingsSuccessPayload,
  IFetchProjectVersionSettingsMultipleSuccessPayload,
  IFetchProjectVersionSettingsDemoSuccessPayload,
} from './types'

import {
  ProjectVersionActionTypes,
} from '@SiteContainers/ProjectVersionsList/types'

const initialProjectVersionSettings: IProjectVersionSettings = {
  attributes: {
    title: '',
    duration: 0,
    language: '',
    availableTotalCredits: 0,
    subtitleLanguages: [],
    languagesForVideos: [],
    translationLanguages: [],
    nonTranslatableLanguages: [],
    alignmentLanguages: [],
    translationFormalityOptionLanguages: [],
    voiceCloningLanguages: [],
    hasMainAssets: false,
    hasAudioSeparation: false,
    subscriptionSubtitlesLength: 0,
  }
}

export const initialState: IProjectVersionSettingsState = {
  isReadyForTheme: false,
  loading: true,
  projectVersionSettings: initialProjectVersionSettings,
}

export const projectVersionSettingsReducer = (
  state: IProjectVersionSettingsState = initialState,
  action: IProjectVersionSettingsActionTypes
): IProjectVersionSettingsState => {
  switch (action.type) {
    case ProjectVersionSettingsActionTypes.FETCH_PROJECT_VERSION_SETTINGS:
    case ProjectVersionSettingsActionTypes.FETCH_PROJECT_VERSION_SETTINGS_MULTIPLE:
    case ProjectVersionSettingsActionTypes.FETCH_PROJECT_VERSION_SETTINGS_DEMO:
      return fetchProjectVersionSettings(state)
    case ProjectVersionSettingsActionTypes.FETCH_PROJECT_VERSION_SETTINGS_SUCCESS:
    case ProjectVersionSettingsActionTypes.FETCH_PROJECT_VERSION_SETTINGS_MULTIPLE_SUCCESS:
    case ProjectVersionSettingsActionTypes.FETCH_PROJECT_VERSION_SETTINGS_DEMO_SUCCESS:
      return fetchProjectVersionSettingsSuccess(state, action)
    case ProjectVersionSettingsActionTypes.PROJECT_VERSION_SETTINGS_FAILURE: return projectVersionSettingsFailure(state)
    case ProjectVersionSettingsActionTypes.SUBMIT_PROJECT_VERSION_UPDATE: return submitProjectVersionUpdate(state)
    case ProjectVersionSettingsActionTypes.SUBMIT_PROJECT_VERSION_UPDATE_SUCCESS: return submitProjectVersionUpdateSuccess(state)
    case ProjectVersionSettingsActionTypes.SUBMIT_PROJECT_VERSION_UPDATE_FAILURE: return submitProjectVersionUpdateFailure(state)
    case ProjectVersionActionTypes.CLOSE_POPUP: return resetProjectVersionSettings(state)
    default: return state
  }
}

const fetchProjectVersionSettings = (state: IProjectVersionSettingsState): IProjectVersionSettingsState => {
  return { ...state, loading: true }
}

const submitProjectVersionUpdate = (state: IProjectVersionSettingsState): IProjectVersionSettingsState => {
  return { ...state, loading: true, isReadyForTheme: false }
}

const submitProjectVersionUpdateSuccess = (state: IProjectVersionSettingsState): IProjectVersionSettingsState => {
  return { ...state, loading: false, isReadyForTheme: true }
}

function isIFetchProjectVersionSettingsSuccessAction(arg: any): arg is IAction<IFetchProjectVersionSettingsSuccessPayload | IFetchProjectVersionSettingsMultipleSuccessPayload | IFetchProjectVersionSettingsDemoSuccessPayload> {
  return arg.payload != null && arg.payload.attributes != null
}

const fetchProjectVersionSettingsSuccess = (state: IProjectVersionSettingsState, action: IProjectVersionSettingsActionTypes): IProjectVersionSettingsState => {
  let projectVersionSettings = initialProjectVersionSettings

  if (isIFetchProjectVersionSettingsSuccessAction(action)) {
    const newProjectVersionSettingsAttributes = {...projectVersionSettings.attributes, ...action.payload.attributes}
    projectVersionSettings = { ...projectVersionSettings, attributes: newProjectVersionSettingsAttributes }
  }

  return { ...state, projectVersionSettings, loading: false }
}

const projectVersionSettingsFailure = (state: IProjectVersionSettingsState): IProjectVersionSettingsState => {
  return { ...state, loading: false }
}

const submitProjectVersionUpdateFailure = (state: IProjectVersionSettingsState): IProjectVersionSettingsState => {
  return { ...state, loading: false }
}

const resetProjectVersionSettings = (state: IProjectVersionSettingsState) => {
  return { ...state, ...initialState }
}
